import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Select, Icon, Checkbox ,notification} from 'antd';
import { get, post, put } from '../../../utils';
import './style.scss'

const formLanguageLayout = {
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;
const { TextArea } = Input;

class NotificationManualSend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languageList:[],
            subject: '',
            description: '',
            notificationManualLanguageList: this.props.languageList.map(item => ({
                subject: '',
                description: '',
                language_id: item.id,
            })),
            status: 'save',
            originalStatus: null,
            notificationType: '',
            notificationTypeList: [
                { value: 'product', label: 'Product' },
                { value: 'article', label: 'Article' },
            ],
            sendToType: '',
            sendToList: [
                { value: 'all', label: 'All' },
                { value: 'follow_product', label: 'Favorite Product' },
                ...this.props.mainCustomerId == 8 ? [] : [{ value: 'tag', label: 'Tag' }],
            ],
            productId: null,
            sendToFollowProduct: null,
            productList: [],
            articleId: null,
            articleList: [],
            sendToTicketTag: null,
            ticketTagList: [],

            titlechecked:false
        }
    }

    isDisabled() {
        const { originalStatus } = this.state
        if (originalStatus == 'sent') {
            return true
        }
        return false
    }

    getProductList() {
        let url = '/api/product';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ productList: response.dataList })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getArticleList(languageCode) {
        let url = '/api/article?language=' + languageCode;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ articleList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getTicketTagList() {
        let url = '/api/ticket/tag';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ ticketTagList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getNotificationManualSendDetail() {
        const { editId } = this.props
        let url = '/api/administrator/crm/notification/manual/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.languageList.map(item => {
                    let index = response.notification_manual_language_list.findIndex(itemData => itemData.language_id == item.id)
                    if (index == -1) {
                        response.notification_manual_language_list.push({ name: '', language_id: item.id })
                    }
                })
                this.setState({
                    subject: response.subject,
                    description: response.description,
                    productId: response.product_id,
                    articleId: response.article_id,
                    notificationType: response.notification_type,
                    link: response.link,
                    sendToType: response.send_to_type,
                    sendToFollowProduct: response.send_to_follow_product,
                    sendToTicketTag: response.send_to_ticket_tag,
                    status: response.status,
                    originalStatus: response.status,
                    notificationManualLanguageList: response.notification_manual_language_list
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getLanguageList() {
        let url = '/api/crm/administrator/language/raw';
        return get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ languageList: responses })
                this.getArticleList(responses[0].code)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async componentDidMount() {
        this.getProductList()
        this.getLanguageList()
        this.getTicketTagList()
        if (this.props.editId) {
            this.getNotificationManualSendDetail()
        }
    }
    


    updateNotificationManualSend(status) {
        const { subject, description, notificationType, sendToType, sendToFollowProduct, sendToTicketTag, productId, articleId,
            notificationManualLanguageList
         } = this.state
        // if (!subject || subject == '') {
        //     openNotificationWithIcon('warning', 'System notification', 'Please enter "Header".')
        //     return
        // }
        if(notificationManualLanguageList.every(item => !item.subject)){
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Header".')
            return
        }

        if (!notificationType || notificationType == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please select "Additional Information".')
            return
        }else{
            if (notificationType == 'product' && !productId || productId == '') {
                openNotificationWithIcon('warning', 'System notification', 'Please select "Product".')
                return
            }
            if (notificationType == 'article' && !articleId || articleId == '') {
                openNotificationWithIcon('warning', 'System notification', 'Please select "Article".')
                return
            }
        }
        if (!sendToType || sendToType == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please select "Audience Group".')
            return
        }else{
            if(sendToType == 'follow_product' && !sendToFollowProduct || sendToFollowProduct == ''){
                openNotificationWithIcon('warning', 'System notification', 'Please select "Product".')
                return
            }
            if(sendToType == 'tag' && !sendToTicketTag || sendToTicketTag == ''){
                openNotificationWithIcon('warning', 'System notification', 'Please select "Tag".')
                return
            }
        }
        let data = {
            subject: subject,
            description: description,
            send_to_type: sendToType,
            notification_type: notificationType,
            send_to_follow_product: sendToFollowProduct,
            send_to_ticket_tag: sendToTicketTag,
            product_id: productId,
            article_id: articleId,
            status: status,
            user: this.props.administratorId,

            notification_manual_language_list: notificationManualLanguageList,
            
        }

        let url = '/api/administrator/crm/notification/manual';
        if (this.props.editId) {
            url = '/api/administrator/crm/notification/manual/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        }


    }

    onClickOk() {
        this.updateNotificationManualSend('save')
    }
    onClickCancel() {
        this.setState({
            subject: '',
            description: '',
        })
        this.props.backpage()
    }
    onClickSaveAndSend() {
        this.updateNotificationManualSend('sent')
    }

    onClickSelectProduct(value) {
        if(value == 'product') {
            return(
                <Select
                    value={this.state.productId}
                    onChange={(value) => this.setState({ productId: value })}
                    disabled={this.isDisabled()}
                    style={{display: this.state.notificationType == 'product' ? 'block' : 'none' ,width:'300px'}}
                >
                    {this.state.productList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                </Select>
            )
        } 
        if(value == 'article') {
            return(
                <Select
                    value={this.state.articleId}
                    onChange={(value) => this.setState({ articleId: value })}
                    disabled={this.isDisabled()}
                    style={{display: this.state.notificationType == 'article' ? 'block' : 'none' ,width:'300px'}}
                >
                    {this.state.articleList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                </Select>
            )
        }
        
    }
    
    onClickSelectGroup(value) {
        if(value == 'follow_product') {
            return(
                <Select
                    value={this.state.sendToFollowProduct}
                    onChange={(value) => this.setState({ sendToFollowProduct: value })}
                    disabled={this.isDisabled()}
                    style={{display: this.state.sendToType == 'follow_product' ? 'block' : 'none' ,width:'300px' }}
                >
                    {this.state.productList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                </Select>
            )
        }
        if(value == 'tag') {
            return(
                <Select
                    value={this.state.sendToTicketTag}
                    onChange={(value) => this.setState({ sendToTicketTag: value })}
                    disabled={this.isDisabled()}
                    style={{display: this.state.sendToType == 'tag' ? 'block' : 'none' ,width:'300px' }}
                >
                    {this.state.ticketTagList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                </Select>
            )
        }
    }

    updateNotificationLanguage(index, name, value) {
        let notificationManualLanguageList = this.state.notificationManualLanguageList.map(item => Object.assign({}, item))
        notificationManualLanguageList[index][name] = value
        this.setState({ notificationManualLanguageList: notificationManualLanguageList })
    }

    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const { notificationManualLanguageList } = this.state
        
        return (
            <div className='notificationmanualsend-page-wrapper' style={{ backgroundColor: '#ffffff', height: '90vh' }}>
                <div className="header-card" >
                    <Col sm={24} md={12} lg={12} style={{ textAlign: 'left', marginBottom:10, cursor: 'pointer' }} onClick={() => this.props.backpage()} >
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit Manual Broad cast' : 'Create Manual Broad cast'}</span>
                    </Col>
                    <Col sm={24} md={12} lg={12} style={{ textAlign: 'right', marginBottom:10 }}>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }}
                            onClick={() => this.onClickOk()}
                            disabled={this.isDisabled()}
                        >
                            {'Save'}
                        </Button>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(55, 168, 0)', color: '#ffffff', border: '1px solid rgb(55, 168, 0)' }}
                            onClick={() => this.onClickSaveAndSend()}
                            disabled={this.isDisabled()}
                        >
                            {'Save & Send'}
                        </Button>
                    </Col>
                </div>
                <Card className="cardstyle">
                    <Form className="from-border-box">
                        <Row className="row-box">
                            <Col>
                                <div className="header-row">
                                    <Checkbox checked={notificationManualLanguageList.some(item => item.subject)}  style={{ marginBottom: "10px" }} />
                                    <h3 style={{marginLeft:10}}><span style={{color:'red'}}>* </span>Header</h3>
                                   
                                </div>
                                {this.state.notificationManualLanguageList.map((item, index) => {
                                    return (
                                        <Card style={{ marginBottom: 10 }} className='language-card'>
                                            <Row gutter={24}>
                                                <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                                    <FormItem {...formLanguageLayout} label={'Line ID'}>
                                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                                    </FormItem>
                                                </Col>
                                                <Col xs={24} sm={24} md={18} lg={18} xl={18} >
                                                    <FormItem {...formLanguageLayout} label={'Language'} required={true} style={{textAlign: "start"}}>
                                                        <Select
                                                            allowClear={true}
                                                            value={item.language_id}
                                                            onChange={(event) => this.updateNotificationLanguage(index, 'language_id', event.target.value)}
                                                        >
                                                            {this.props.languageList.length ?
                                                                this.props.languageList.map((item, index) => 
                                                                <Option 
                                                                    key={index} 
                                                                    value={item.id} 
                                                                    disabled={this.state.notificationManualLanguageList.map(r => r.language_id).includes(item.id)}
                                                                >
                                                                    {item.name}
                                                                </Option>)
                                                                : null}
                                                        </Select>
                                                    </FormItem>
                                                </Col>
                                                <Input
                                                    value={item.subject}
                                                    onChange={(event) => this.updateNotificationLanguage(index, 'subject', event.target.value)}
                                                    disabled={this.isDisabled()}
                                                    // style={{border:'none'}}
                                                />
                                            </Row>
                                        </Card>
                                    )
                                })}
                            </Col>
                        </Row>
                        <Row className="row-box">
                            <Col>
                                <div className="header-row">
                                    <Checkbox checked={notificationManualLanguageList.some(item => item.description)} style={{ marginBottom: "10px" }} />
                                    <h3 style={{marginLeft:10}}><span style={{color:'red'}}>* </span>Description</h3>
                                </div>
                                {this.state.notificationManualLanguageList.map((item, index) => {
                                    return (
                                        <Card style={{ marginBottom: 10 }} className='language-card'>
                                            <Row gutter={24}>
                                                <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                                    <FormItem {...formLanguageLayout} label={'Line ID'}>
                                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                                    </FormItem>
                                                </Col>
                                                <Col xs={24} sm={24} md={18} lg={18} xl={18} >
                                                    <FormItem {...formLanguageLayout} label={'Language'} required={true} style={{textAlign: "start"}}>
                                                        <Select
                                                            allowClear={true}
                                                            value={item.language_id}
                                                            onChange={(event) => this.updateNotificationLanguage(index, 'language_id', event.target.value)}
                                                        >
                                                            {this.props.languageList.length ?
                                                                this.props.languageList.map((item, index) => 
                                                                <Option 
                                                                    key={index} 
                                                                    value={item.id} 
                                                                    disabled={this.state.notificationManualLanguageList.map(r => r.language_id).includes(item.id)}
                                                                >
                                                                    {item.name}
                                                                </Option>)
                                                                : null}
                                                        </Select>
                                                    </FormItem>
                                                </Col>
                                                <TextArea
                                                    value={item.description}
                                                    onChange={(event) => this.updateNotificationLanguage(index, 'description', event.target.value)}
                                                    disabled={this.isDisabled()}
                                                    rows={4}
                                                    // style={{border:'none'}}
                                                />
                                            </Row>
                                        </Card>
                                    )
                                })}
                                {/* <TextArea
                                    value={this.state.description}
                                    onChange={(event) => this.setState({ description: event.target.value })}
                                    disabled={this.isDisabled()}
                                    rows={4}
                                    style={{border:'none'}}
                                /> */}
                            </Col>
                        </Row>
                        <Row className="row-box">
                            <Col>
                                <div className="header-row">
                                    <Checkbox checked={this.state.productId != null || this.state.articleId != null ? true : false}  style={{ marginBottom: "10px" }} />
                                    <h3 style={{marginLeft:10}}><span style={{color:'red'}}>* </span>Additional Information</h3>
                                    <span style={{color:'#b4b4b4',marginLeft:10}}>: Please select additional information to be broadcast </span>
                                </div>
                                {this.state.notificationTypeList.map((item, index) => {
                                    return (
                                        <div className="card-itembox">
                                            <Button 
                                                onClick={() => this.setState({ notificationType: item.value })} 
                                                style={{
                                                    backgroundColor:this.state.notificationType == item.value ? '#48c5eb' : '#fff',
                                                    color:this.state.notificationType == item.value ? '#fff' : '#000',
                                                    width:'150px',
                                                    textAlign:'left',
                                                    paddingLeft:'20px'
                                                }} 
                                            >
                                                <Icon type={item.value == 'product' ? 'dropbox' : 'book'} />
                                                {item.label}
                                            </Button>
                                            {this.onClickSelectProduct(item.value)}
                                        </div>
                                    )
                                })}
                            </Col>
                        </Row>
                        <Row className="row-box">
                            <Col>
                                <div className="header-row">
                                    <Checkbox checked={this.state.sendToFollowProduct != null || this.state.sendToTicketTag != null || this.state.sendToType == 'all' ? true : false}  style={{ marginBottom: "10px" }} />
                                    <h3 style={{marginLeft:10}}><span style={{color:'red'}}>* </span>Audience Group</h3>
                                    <span style={{color:'#b4b4b4',marginLeft:10}}>: Please select audience group to be broadcast</span>
                                </div>
                                {this.state.sendToList.map((item, index) => {
                                    return (
                                        <div className="card-itembox">
                                            <Button 
                                                onClick={() => this.setState({ sendToType: item.value })}
                                                style={{
                                                    backgroundColor:this.state.sendToType == item.value ? '#48c5eb' : '#fff',
                                                    color:this.state.sendToType == item.value ? '#fff' : '#000',
                                                    width:'150px',
                                                    textAlign:'left',
                                                    paddingLeft:'20px'
                                                }}
                                            >
                                                <Icon type={item.value == 'follow_product' ? 'heart' : item.value == 'all' ? 'user' : 'tag'} />
                                                {item.label}
                                            </Button>
                                            {this.onClickSelectGroup(item.value)}
                                        </div>
                                    )
                                })}
                            </Col>
                        </Row>


                        {/* <Row gutter={24}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Subject'}>
                                    <Input
                                        value={this.state.subject}
                                        onChange={(event) => this.setState({ subject: event.target.value })}
                                        disabled={this.isDisabled()}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Description'}>
                                    <Input
                                        value={this.state.description}
                                        onChange={(event) => this.setState({ description: event.target.value })}
                                        disabled={this.isDisabled()}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Type'}>
                                    <Select
                                        value={this.state.notificationType}
                                        onChange={(value) => this.setState({ notificationType: value })}
                                        disabled={this.isDisabled()}
                                    >
                                        {this.state.notificationTypeList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            {
                                this.state.notificationType == 'product' ?
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Product'}>
                                            
                                            <Select
                                                value={this.state.productId}
                                                onChange={(value) => this.setState({ productId: value })}
                                                disabled={this.isDisabled()}
                                            >
                                                {this.state.productList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    :
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Article'}>
                                            <Select
                                                value={this.state.articleId}
                                                onChange={(value) => this.setState({ articleId: value })}
                                                disabled={this.isDisabled()}
                                            >
                                                {this.state.articleList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                            }

                        </Row>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Sent to'}>
                                    <Select
                                        value={this.state.sendToType}
                                        onChange={(value) => this.setState({ sendToType: value })}
                                        disabled={this.isDisabled()}
                                    >
                                        {this.state.sendToList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            {
                                this.state.sendToType == 'follow_product' ?
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Sent to favorite product'}>
                                            <Select
                                                value={this.state.sendToFollowProduct}
                                                onChange={(value) => this.setState({ sendToFollowProduct: value })}
                                                disabled={this.isDisabled()}
                                            >
                                                {this.state.productList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    :
                                    null
                            }
                            {
                                this.state.sendToType == 'tag' ?
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Sent to tag'}>
                                            <Select
                                                value={this.state.sendToTicketTag}
                                                onChange={(value) => this.setState({ sendToTicketTag: value })}
                                                disabled={this.isDisabled()}
                                            >
                                                {this.state.ticketTagList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    :
                                    null
                            }
                        </Row> */}
                    </Form>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    languageList: state.customerLanguage.languageList,
    mainCustomerId: state.login.mainCustomerId,
})

export default connect(mapStateToProps, null)(NotificationManualSend);

